import { LinkResolverFunction } from '@prismicio/helpers';

import type { FilledContentRelationshipField } from '@prismicio/types';

const UID_DELIMITER = '___';

export const normalizeUid = (uid?: string, type?: string) => {
  let normalizedUid = uid;
  if (uid?.includes(UID_DELIMITER)) {
    const delimiterIndex = uid.indexOf(UID_DELIMITER);

    normalizedUid = uid.slice(0, delimiterIndex);
  }

  switch (type) {
    case 'blog_article': {
      return `blog/${normalizedUid}`;
    }
    default: {
      return normalizedUid;
    }
  }
};

export const linkResolver: LinkResolverFunction = ({
  uid,
  type,
}: FilledContentRelationshipField) => {
  return `/${normalizeUid(uid, type) ?? ''}`;
};
