exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-preview-tsx": () => import("./../../../src/pages/preview.tsx" /* webpackChunkName: "component---src-pages-preview-tsx" */),
  "component---src-templates-blog-archive-page-tsx": () => import("./../../../src/templates/BlogArchive/Page.tsx" /* webpackChunkName: "component---src-templates-blog-archive-page-tsx" */),
  "component---src-templates-blog-article-page-tsx": () => import("./../../../src/templates/BlogArticle/Page.tsx" /* webpackChunkName: "component---src-templates-blog-article-page-tsx" */),
  "component---src-templates-checkout-page-tsx": () => import("./../../../src/templates/Checkout/Page.tsx" /* webpackChunkName: "component---src-templates-checkout-page-tsx" */),
  "component---src-templates-home-page-tsx": () => import("./../../../src/templates/Home/Page.tsx" /* webpackChunkName: "component---src-templates-home-page-tsx" */),
  "component---src-templates-privacy-policy-page-tsx": () => import("./../../../src/templates/PrivacyPolicy/Page.tsx" /* webpackChunkName: "component---src-templates-privacy-policy-page-tsx" */),
  "component---src-templates-quiz-page-tsx": () => import("./../../../src/templates/Quiz/Page.tsx" /* webpackChunkName: "component---src-templates-quiz-page-tsx" */),
  "component---src-templates-terms-and-conditions-page-tsx": () => import("./../../../src/templates/TermsAndConditions/Page.tsx" /* webpackChunkName: "component---src-templates-terms-and-conditions-page-tsx" */),
  "component---src-templates-thank-you-page-tsx": () => import("./../../../src/templates/ThankYou/Page.tsx" /* webpackChunkName: "component---src-templates-thank-you-page-tsx" */)
}

