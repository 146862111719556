import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

import { GLOBAL_STATE_PREFIX } from './constants';

const { persistAtom } = recoilPersist();

export const userEmail = atom<string>({
  key: `${GLOBAL_STATE_PREFIX}-userEmail`,
  default: '',
  effects: [persistAtom],
});

export const anonymousUserId = atom({
  key: `${GLOBAL_STATE_PREFIX}-anonymousUserId`,
  default: '',
});

export const paymentIsOpen = atom({
  key: `${GLOBAL_STATE_PREFIX}-paymentIsOpen`,
  default: false,
});

export default {
  userEmail,
  anonymousUserId,
  paymentIsOpen,
};
