import React from 'react';
import { Global, css } from '@emotion/react';
import normalize from 'emotion-normalize';

import composeFontSize from 'utils/composeFontSize';

import colors from './colors';
import { BASE_REM, GLOBAL_STYLE_VARIABLES } from './constants';
import mq from './mediaQueries';
import { FONT_FAMILIES } from './typography';
import utils from './utils';

const fontFamilies = Object.keys(FONT_FAMILIES).join(',');

const globalStyles = css`
  ${normalize}

  html,
  body {
    margin: 0;
    padding: 0;
  }

  html {
    ${GLOBAL_STYLE_VARIABLES.margin}: 1fr;
    ${GLOBAL_STYLE_VARIABLES.container}: ${utils.rem(
      mq.xl.container as number
    )};

    letter-spacing: 0.02em;
    font-family: ${fontFamilies}, sans-serif;
    font-size: ${BASE_REM}px;
    font-feature-settings: 'pnum' on, 'lnum' on, 'kern' off, 'liga' off;
    scroll-behavior: smooth;

    &::after {
      position: fixed;
      inset: -50%;
      z-index: -1;
      background: ${colors.neutral.white};
      content: '';
    }

    /* ----- Desktop ----- */
    ${mq.xl.down} {
      color: black;
      font-size: ${composeFontSize({
        scaleMax: 100,
        scaleMin: 87.5,
        priorityWidth: 1280,
        precision: 4,
        widthMax: 1440,
        widthMin: 1024,
      })};
    }

    /* ----- Tablet ----- */
    ${mq.lg.down} {
      ${GLOBAL_STYLE_VARIABLES.margin}: ${utils.rem(mq.lg.margin as number)};
      ${GLOBAL_STYLE_VARIABLES.container}: 1fr;

      font-size: ${composeFontSize({
        scaleMax: 87.5,
        scaleMin: 75,
        priorityWidth: 960,
        precision: 4,
        widthMax: 1024,
        widthMin: 768,
      })};
    }

    /* ----- Mobile ----- */
    ${mq.md.down} {
      ${GLOBAL_STYLE_VARIABLES.margin}: 1fr;
      ${GLOBAL_STYLE_VARIABLES.container}: ${utils.rem(
        mq.md.container as number
      )};

      @media (orientation: landscape) {
        font-size: ${BASE_REM}px;
      }
      @media (orientation: portrait) {
        font-size: ${composeFontSize({
          scaleMax: 120,
          scaleMin: 100,
          priorityWidth: 576,
          widthMax: 768,
          widthMin: 480,
        })};
      }
    }

    ${mq.sm.down} {
      ${GLOBAL_STYLE_VARIABLES.margin}: ${utils.rem(mq.sm.margin as number)};
      ${GLOBAL_STYLE_VARIABLES.container}: 1fr;

      font-size: ${BASE_REM}px;
    }

    ${mq.xs.down} {
      font-size: ${composeFontSize({
        scaleMax: 100,
        scaleMin: 80,
        priorityWidth: 320,
        widthMax: 375,
        widthMin: 240,
      })};
    }
  }

  body {
    ${GLOBAL_STYLE_VARIABLES.scrollbarWidth}: 0;
    ${GLOBAL_STYLE_VARIABLES.scrollbarCompensation}: calc(0 * 1px);

    min-height: 100vh;

    @supports (min-height: 100dvh) {
      min-height: 100dvh;
    }

    &[data-scroll-lock] {
      ${GLOBAL_STYLE_VARIABLES.scrollbarCompensation}: var(${GLOBAL_STYLE_VARIABLES.scrollbarWidth});

      padding-right: var(${GLOBAL_STYLE_VARIABLES.scrollbarCompensation});
      overflow: hidden;
    }

    ${mq.use(479).down} {
      & #cb-container {
        transform: scale(0.9);
      }
    }
  }

  *,
  *::after,
  *::before {
    box-sizing: border-box;
    color: ${colors.primary.dark};

    /* Prevents highlight for mobile devices (except Safari) */
    -webkit-tap-highlight-color: #0000;

    /* 
      Prevents highlight for mobile Safari devices
      Narrowed to focused pseudo states
     */
    &:focus,
    &:visited,
    &:active {
      outline: none;
    }
  }

  body #${'CookiebotWidget'}:not(.CookiebotWidget-open) {
    display: none !important;
  }
`;

const GlobalStyle: React.FC = () => <Global styles={globalStyles} />;

export default GlobalStyle;
