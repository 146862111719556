import React from 'react';
import * as Sentry from '@sentry/gatsby';
import { GatsbySSR } from 'gatsby';

import { isDev } from 'utils/constants';

import PreviewProvider from './PreviewProvider';

Sentry.init({
  dsn: process.env.GATSBY_SENTRY_DSN,
  environment: isDev ? 'development' : 'production',
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  tracesSampleRate: isDev ? 1.0 : 0.2,
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0.1,
});

const Root: GatsbySSR['wrapRootElement'] = ({ element }) => {
  return <PreviewProvider>{element}</PreviewProvider>;
};

export default Root;
