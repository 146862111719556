import ClassNames from './ClassNames';
import GlobalStyle from './GlobalStyle';
import colors from './colors';
import { GLOBAL_STYLE_VARIABLES } from './constants';
import depths from './depths';
import durations from './durations';
import { useMatchMedia } from './hooks';
import mediaQueries from './mediaQueries';
import spacings from './spacings';
import typography from './typography';
import utils from './utils';

const theme = {
  colors,
  depths,
  durations,
  mq: mediaQueries,
  spacings,
  tp: typography,
  utils,
};

export {
  theme as default,
  ClassNames,
  GlobalStyle,
  GLOBAL_STYLE_VARIABLES,
  useMatchMedia,
};
