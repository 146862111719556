import Cookies, { CookieAttributes } from 'js-cookie';

import { isDev, LEYA_AI_DOMAIN } from './constants';

export enum COOKIE_KEY {
  COUPON = 'coupon',
  YOURS_CID = 'yours_cid',
  FBCLID = 'fbclid',
  GCLID = 'gclid',
  _FBP = '_fbp',
  _FBC = '_fbc',
  AF_USER_ID = 'afUserId',
  TUNE_OFFER_ID = 'tune_offer_id',
  TUNE_TRANSACTION_ID = 'tune_transaction_id',
  UTM_SOURCE = 'utm_source',
  UTM_MEDIUM = 'utm_medium',
  UTM_CAMPAIGN = 'utm_campaign',
  WEB_PLAN_ID = 'web_plan_id',
  IMPACT_CLICK_ID = 'impact_click_id',
  ANONYMOUS_USER_ID = 'anonymous_user_id',
  EXPERIMENT_SEGMENT = 'experiment_segment',
  HAS_BEEN_DISCOUNTED = 'has_been_discounted',
  SENT_STRIPE_PURCHASE_EVENT_CACHE = 'sent_stripe_purchase_event_cache',
}

export const setCookie = (
  key: COOKIE_KEY,
  value: string,
  options: CookieAttributes = {}
) => {
  Cookies.set(key, value, {
    path: '/',
    expires: 30,
    ...(isDev ? {} : { domain: LEYA_AI_DOMAIN }),
    ...options,
  });
};

export const removeCookie = (key: COOKIE_KEY) => {
  Cookies.remove(key, { path: '/', domain: LEYA_AI_DOMAIN });
};

export const getCookie = (key: COOKIE_KEY) => {
  return Cookies.get(key) || '';
};
