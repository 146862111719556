import React, { PropsWithChildren } from 'react';
import { RecoilRoot } from 'recoil';

import { BasePageContext } from 'utils/gatsby/types';

import { useStateInitializationService } from './hooks';

interface IStateInitialization {
  pageContext: BasePageContext;
}

const StateInitialization: React.FC<IStateInitialization> = ({
  pageContext,
}) => {
  useStateInitializationService(pageContext);
  return null;
};

const StateProvider: React.FC<PropsWithChildren<IStateInitialization>> = ({
  children,
  pageContext,
}) => {
  return (
    <RecoilRoot>
      <StateInitialization pageContext={pageContext} />
      {children}
    </RecoilRoot>
  );
};

export default StateProvider;
