import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import { PATHS, METHODS, API } from './constants';

interface IQueryParameter {
  key: string;
  value: string | number;
}

type ICallApiProps<Data> = {
  method: METHODS;
  mainPath: PATHS;
  data?: Data;
  queryParams?: IQueryParameter[];
  extraPath?: string;
  authorized?: boolean;
  options?: Partial<AxiosRequestConfig<Data>>;
};

const callApi = <
  Data extends object = object,
  Response extends object = object
>({
  method,
  mainPath,
  data,
  queryParams = [],
  extraPath = '',
  options = {},
}: ICallApiProps<Data>): Promise<AxiosResponse<Response>> => {
  const params = queryParams
    .map(
      (parameter, index) =>
        `${
          parameter.value
            ? `${index === 0 ? '?' : '&'}${parameter.key}=${encodeURIComponent(
                String(parameter.value)
              )}`
            : ''
        }`
    )
    .join('');

  return axios<Data, AxiosResponse<Response>>({
    method,
    url: `${API}${mainPath}${extraPath}${params?.length ? params : ''}`,
    data,
    ...options,
  });
};

export default callApi;
