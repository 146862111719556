export enum PATHS {
  CREATE_ANONYMOUS = '/v1/user/create-anonymous/',
  UPDATE_ANALYTICS = '/v1/user/update-analytics/',
  UPGRADE_ANONYMOUS_TO_LEAD = '/v1/user/upgrade-anonymous-to-lead/',
  USER_ANSWERS = '/v1/questions/user-answers/',
  STRIPE_CHECKOUT = '/v1/payments/stripe/checkout/',
  STRIPE_SESSION = '/v1/payments/stripe/session/',
  STRIPE_SUBSCRIPTIONS = '/v1/payments/stripe/subscriptions/',
  STRIPE_USERS = '/v1/payments/stripe/users/',
  PAYMENTS_PLANS = '/v1/payments/plans/',
}

export enum METHODS {
  POST = 'post',
  GET = 'get',
  PATCH = 'patch',
  PUT = 'put',
  DELETE = 'delete',
}

export const API_STATUS_CODES = {
  UNAUTHORIZED: 401,
};

export const API =
  process.env.NODE_ENV === 'development' && typeof window !== 'undefined'
    ? '/v1'
    : `${process.env.GATSBY_BACKEND_HTTPS_DOMAIN}/api`;
