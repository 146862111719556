import { useEffect, useRef } from 'react';
import { captureException } from '@sentry/gatsby';
import { useRecoilState, useRecoilValue } from 'recoil';

import callApi, { METHODS, PATHS } from 'api';
import {
  useExperimentInitialization,
  experimentSegment as experimentSegmentState,
} from 'components/organisms/Experiment';
import { anonymousUserId as anonymousUserIdState } from 'state/atoms';
import { QUERY_PARAMS } from 'utils/constants';
import { COOKIE_KEY, getCookie, setCookie } from 'utils/cookies';

import type { PageProps } from 'gatsby';
import type { BasePageContext } from 'utils/gatsby/types';

export const useStateInitializationService = (pageContext: BasePageContext) => {
  const anonymousUserId = useInitializeAnonymousUserId();
  const experimentSegment = useRecoilValue(experimentSegmentState);

  useExperimentInitialization(pageContext);

  useEffect(() => {
    if (!!anonymousUserId && experimentSegment !== null) {
      if (typeof window !== 'undefined' && window['gtag']) {
        if (window['gtag']) {
          const gtag = window['gtag'];
          gtag('set', { user_id: anonymousUserId });
        }

        if (window['dataLayer']) {
          const dataLayer = window['dataLayer'];

          dataLayer.push({
            event: 'setUserData',
            userId: anonymousUserId,
            ...(experimentSegment ? { experimentSegment } : {}),
          });
        }
      }
    }
  }, [anonymousUserId, experimentSegment]);
};

const useInitializeAnonymousUserId = () => {
  const [currentAnonymousUserId, setAnonymousUserId] =
    useRecoilState(anonymousUserIdState);

  const retriesRef = useRef(0);
  const maxRetries = 6;

  useEffect(() => {
    const anonymousUserId = getCookie(COOKIE_KEY.ANONYMOUS_USER_ID);

    const getFacebookCookie = async (cookie: COOKIE_KEY) => {
      const maxRetries = 6;
      const initialRetryDelay = 250;

      const attemptFetch = async (attempt = 0) => {
        const fbCookie = getCookie(cookie);

        if (fbCookie) {
          return fbCookie;
        } else if (attempt < maxRetries) {
          const delay = initialRetryDelay * Math.pow(2, attempt);
          await new Promise(resolve => setTimeout(resolve, delay));
          return attemptFetch(attempt + 1);
        } else {
          captureException(
            new Error(
              `Failed to retrieve ${cookie} cookie after ${attempt} attempts`
            )
          );
          return null;
        }
      };

      return await attemptFetch();
    };

    if (anonymousUserId) {
      const updateUserAnalytics = async () => {
        await callApi({
          method: METHODS.POST,
          mainPath: PATHS.UPDATE_ANALYTICS,
          data: {
            user_id: anonymousUserId,
            facebook_fbc: await getFacebookCookie(COOKIE_KEY._FBC),
            facebook_fbp: await getFacebookCookie(COOKIE_KEY._FBP),
            facebook_fbclid: getCookie(COOKIE_KEY.FBCLID) || null,
          },
        });
      };

      setAnonymousUserId(anonymousUserId);
      updateUserAnalytics();
    } else if (typeof window !== 'undefined') {
      const getAnonymousUserIdAndSet = async () => {
        try {
          const {
            data: { id },
          } = await callApi<object, { id: string }>({
            method: METHODS.POST,
            mainPath: PATHS.CREATE_ANONYMOUS,
            data: {
              facebook_fbc: await getFacebookCookie(COOKIE_KEY._FBC),
              facebook_fbp: await getFacebookCookie(COOKIE_KEY._FBP),
              facebook_fbclid: getCookie(COOKIE_KEY.FBCLID) || null,
            },
          });

          setCookie(COOKIE_KEY.ANONYMOUS_USER_ID, id, {
            expires: new Date('2030'),
          });
          setAnonymousUserId(id);
        } catch (error) {
          if (retriesRef.current++ < maxRetries) {
            setTimeout(getAnonymousUserIdAndSet, 10000);
          }
          captureException(error);
        }
      };

      getAnonymousUserIdAndSet();
    }
  }, []);

  return currentAnonymousUserId;
};

export const useQueryParams = ({ location }: PageProps) => {
  const params = new URLSearchParams(location.search);

  const fbclid = params.get(QUERY_PARAMS.FBCLID);
  const gclid = params.get(QUERY_PARAMS.GCLID);
  const coupon = params.get(QUERY_PARAMS.COUPON);
  const tuneOfferId = params.get(QUERY_PARAMS.TUNE_OFFER_ID);
  const tuneTransactionId = params.get(QUERY_PARAMS.TUNE_TRANSACTION_ID);
  const utmCampaign = params.get(QUERY_PARAMS.UTM_CAMPAIGN);
  const utmMedium = params.get(QUERY_PARAMS.UTM_MEDIUM);
  const utmSource = params.get(QUERY_PARAMS.UTM_SOURCE);
  const impactClickId = params.get(QUERY_PARAMS.IMPACT_CLICK_ID);

  if (fbclid) {
    setCookie(COOKIE_KEY.FBCLID, fbclid);
  }

  if (gclid) {
    setCookie(COOKIE_KEY.GCLID, gclid);
  }

  if (coupon) {
    setCookie(COOKIE_KEY.COUPON, coupon);
  }

  if (tuneOfferId) {
    setCookie(COOKIE_KEY.TUNE_OFFER_ID, tuneOfferId);
  }

  if (tuneTransactionId) {
    setCookie(COOKIE_KEY.TUNE_TRANSACTION_ID, tuneTransactionId);
  }

  if (utmCampaign) {
    setCookie(COOKIE_KEY.UTM_CAMPAIGN, utmCampaign);
  }

  if (utmMedium) {
    setCookie(COOKIE_KEY.UTM_MEDIUM, utmMedium);
  }

  if (utmSource) {
    setCookie(COOKIE_KEY.UTM_SOURCE, utmSource);
  }

  if (impactClickId) {
    setCookie(COOKIE_KEY.IMPACT_CLICK_ID, impactClickId);
  }
};
