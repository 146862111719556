export const isDev = process.env.NODE_ENV === 'development';
export const isProd = process.env.NODE_ENV === 'production';

export enum QUERY_PARAMS {
  TOKEN = 'token',
  CODE = 'code',
  WEB_PLAN_ID = 'web_plan_id',
  COUPON = 'coupon',
  LICENSE_ID = 'license_id',
  GCLID = 'gclid',
  FBCLID = 'fbclid',
  TUNE_OFFER_ID = 'tune_offer_id',
  TUNE_TRANSACTION_ID = 'tune_transaction_id',
  UTM_SOURCE = 'utm_source',
  UTM_MEDIUM = 'utm_medium',
  UTM_CAMPAIGN = 'utm_campaign',
  IMPACT_CLICK_ID = 'irclickid',
}

export enum PRISMIC_DOCUMENT_TYPES {
  checkout = 'checkout',
  contact = 'contact',
  faq = 'faq',
  home = 'home',
  privacyPolicy = 'privacy_policy',
  quiz = 'quiz',
  termsAndConditions = 'terms_and_conditions',
  thankYou = 'thank_you',
}

export enum INTERNAL_LINKS {
  HOMEPAGE = '/',
}

export enum APP_LINKS {
  REGISTRATION = '/registration',
}

export enum DATA_LAYER_EVENTS {
  PROVIDE_EMAIL = 'provide_email',
  BEGIN_CHECKOUT = 'begin_checkout',
  STRIPE_PURCHASE = 'stripe_purchase',
}

export const LEYA_AI_DOMAIN = '.leyaai.com';

export const MODAL_ROOT_ID = 'modal_root';
