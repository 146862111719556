import { useCallback, useEffect, useState } from 'react';

import { useScrollLock } from 'utils/hooks';

export const useExperimentLoaderService = (showLoader: boolean) => {
  const [lockScroll, unlockScroll, syncScrollWidth] = useScrollLock();
  const [iterations, setIterations] = useState(0);
  const [shouldRenderLoader, setShouldRenderLoader] = useState(showLoader);
  const [shouldHideLoader, setShouldHideLoader] = useState(!showLoader);

  useEffect(() => {
    if (!showLoader) {
      setIterations(0);
      setShouldHideLoader(true);
    } else {
      setShouldRenderLoader(true);
      setShouldHideLoader(false);
    }
  }, [showLoader]);

  useEffect(() => {
    if (shouldHideLoader && !!iterations) {
      setShouldRenderLoader(false);
    }
  }, [shouldHideLoader, iterations]);

  const handleAnimationIteration = useCallback(() => {
    setIterations(iterations => iterations + 1);
  }, [setIterations]);

  const handleLoaderExited = useCallback(() => {
    unlockScroll();
    syncScrollWidth();
  }, []);

  const handleEnter = useCallback(lockScroll, []);

  return {
    renderLoader: shouldRenderLoader,
    renderContent: shouldHideLoader,
    handleAnimationIteration,
    handleLoaderExited,
    handleEnter,
  };
};
