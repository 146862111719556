import { atom } from 'recoil';

import { GLOBAL_STATE_PREFIX } from 'state';

export const experimentSegment = atom<string | null>({
  key: `${GLOBAL_STATE_PREFIX}-experimentSegment`,
  default: null,
});

export const enabledSegments = atom<string[]>({
  key: `${GLOBAL_STATE_PREFIX}-enabledSegments`,
  default: [],
});

const statsigIsInitialized = atom<boolean>({
  key: `${GLOBAL_STATE_PREFIX}-statsigIsInitialized`,
  default: false,
});

export default {
  experimentSegment,
  enabledSegments,
  statsigIsInitialized,
};
