const colors = {
  neutral: {
    black: '#000000',
    white: '#ffffff',
    grey: '#f3f6f8',
  },
  primary: {
    light: '#7400f6',
    main: '#391f4b',
    dark: '#2b143b',
  },
  secondary: {
    light: '#71c0e2',
    main: '#71c0e2',
    dark: '#bcfdda',
  },
  tertiary: {
    light: '#bcfdda',
    main: '#bcfdda',
    dark: '#71c0e2',
  },
  error: {
    main: '#E93C51',
  },
  gradients: {
    orangePink: 'linear-gradient(#f4c6b9, #d770c6)',
    greenBlue: 'linear-gradient(#bcfdda, #71c0e2)',
    blueGreen: 'linear-gradient(#71c0e2, #bcfdda)',
    single: {
      orange: '#f4c6b9',
      pink: '#d770c6',
      green: '#bcfdda',
      blue: '#71c0e2',
      cyan: '#41b8de',
    },
  },
};

export default colors;
