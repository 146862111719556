import React, { PropsWithChildren } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { CSSTransition } from 'react-transition-group';

import Layout from 'components/atoms/Layout';
import Loader from 'components/atoms/Loader';

import { useExperimentLoaderService } from './hooks';

interface IExperimentLoader extends PropsWithChildren {
  showLoader: boolean;
  overridden: boolean;
}

const CLASSNAME_PREFIX = 'fade';

const Wrapper = styled.div`
  display: grid;
`;

const Container = styled(Layout)`
  ${({ theme: { colors, durations, depths } }) => css`
    position: fixed;
    grid-template-rows: 1fr;
    grid-row: 1 / 1;
    grid-column: 1 / 1;
    align-items: center;
    justify-items: center;
    transition: opacity ${durations.regular};
    opacity: 0;
    z-index: 1;
    width: 100%;
    height: 100%;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      z-index: ${depths.deepdive};
      background-color: ${colors.primary.dark};
      width: 100vw;
      height: 100%;
      content: '';
    }

    &.${CLASSNAME_PREFIX} {
      &-exit,
      &-enter-active,
      &-enter-done {
        opacity: 1;
      }

      &-enter-active {
        transition-delay: ${durations.regular};
        transition-timing-function: ease-in;
      }

      &-enter,
      &-exit-active,
      &-exit-done {
        opacity: 0;
      }

      &-exit-active {
        transition-timing-function: ease-out;
      }
    }
  `}
`;

const Content = styled.div`
  display: grid;
  grid-row: 1 / 1;
  grid-column: 1 / 1;
`;

const StyledLoader = styled(Loader)`
  width: 100%;
  max-width: min(100%, 100rem);
  height: auto;
`;

const ExperimentLoader: React.FC<IExperimentLoader> = ({
  children,
  showLoader,
  overridden,
}) => {
  const {
    renderLoader,
    renderContent,
    handleAnimationIteration,
    handleLoaderExited,
    handleEnter,
  } = useExperimentLoaderService(showLoader);

  return (
    <Wrapper>
      <CSSTransition
        in={renderLoader}
        classNames={CLASSNAME_PREFIX}
        mountOnEnter={!showLoader}
        timeout={300}
        unmountOnExit
        onEnter={handleEnter}
        onExited={handleLoaderExited}
      >
        <Container>
          <StyledLoader onAnimationIteration={handleAnimationIteration} />
        </Container>
      </CSSTransition>
      <CSSTransition
        in={renderContent}
        timeout={0}
        mountOnEnter={!overridden && showLoader}
        onEnter={handleEnter}
      >
        <Content>{children}</Content>
      </CSSTransition>
    </Wrapper>
  );
};

export default ExperimentLoader;
