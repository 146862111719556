import {
  BASE_EM,
  Breakpoint,
  DESKTOP,
  LARGE_MOBILE,
  MOBILE,
  SMALL_MOBILE,
  TABLET,
  TINY_MOBILE,
} from './constants';

const breakpoints = {
  xl: DESKTOP,
  lg: TABLET,
  md: LARGE_MOBILE,
  sm: MOBILE,
  xs: SMALL_MOBILE,
  tn: TINY_MOBILE,
};

const mediaQueries = {
  ...(Object.entries(breakpoints).reduce(
    (acm, [label, { width, inclusive = false, ...breakpoint }]) => {
      const normalizedWidth = inclusive ? width : width - 1;

      return {
        ...acm,
        [label]: {
          up: `@media screen and (min-width: ${
            (normalizedWidth + 1) / BASE_EM
          }em)`,
          down: `@media screen and (max-width: ${normalizedWidth / BASE_EM}em)`,
          ...breakpoint,
        },
      };
    },
    {}
  ) as Record<
    keyof typeof breakpoints,
    { up: string; down: string } & Omit<Breakpoint, 'width' | 'inclusive'>
  >),
  hover: '@media (hover: hover)',
  notIos: '@supports not (-webkit-touch-callout: none)',
  ios: '@supports (-webkit-touch-callout: none)',
  use: (breakpoint: number) => ({
    up: `@media screen and (min-width: ${breakpoint + 1}px)`,
    down: `@media screen and (max-width: ${breakpoint}px)`,
  }),
};

export default mediaQueries;
