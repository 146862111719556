import React, { ReactElement, ReactNode } from 'react';
import {
  ClassNames as EmotionClassNames,
  SerializedStyles,
} from '@emotion/react';

import type { Classnames, InterpolationWithTheme } from 'utils/types';

type ClassNamesProps<Keys extends string> = {
  children(classes: Record<Keys, string>): ReactNode;
} & Classnames<Keys>;

const ClassNames = <Keys extends string>({
  children,
  classNames = {},
}: ClassNamesProps<Keys>): ReactElement => (
  <EmotionClassNames>
    {({ cx, css: consumeStyles, theme }) =>
      children(
        Object.entries(classNames).reduce(
          (acm, [className, styles]) => ({
            ...acm,
            [className]: cx(
              consumeStyles(
                (typeof styles === 'object'
                  ? styles
                  : (styles as InterpolationWithTheme)(
                      theme
                    )) as SerializedStyles
              )
            ),
          }),
          {} as Record<Keys, string>
        )
      )
    }
  </EmotionClassNames>
);

export default ClassNames;
