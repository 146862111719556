import { useCallback, useEffect, useRef, useState } from 'react';
import * as prismic from '@prismicio/client';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import Statsig from 'statsig-js';

import { COOKIE_KEY, getCookie, removeCookie, setCookie } from 'utils/cookies';

import atoms, {
  enabledSegments,
  enabledSegments as enabledSegmentsState,
  experimentSegment,
} from './atoms';
import {
  EXPERIMENTS,
  STATSIG_EVENTS,
  STATSIG_EXPERIMENTS,
  STATSIG_FEATURE_GATES,
} from './constants';

import type { TimestampField } from '@prismicio/client/src/types/value/timestamp';
import type { BasePageContext } from 'utils/gatsby/types';
import type { OmitFirstTuple } from 'utils/types';

export const useExperimentInitialization = ({
  experimentSegmentsMap = {},
  enabledSegments,
}: BasePageContext) => {
  const setExperimentSegment = useSetRecoilState(experimentSegment);
  const setEnabledSegments = useSetRecoilState(enabledSegmentsState);

  const getWeightedSegment = useCallback(() => {
    const experimentsEnabled = !!Object.keys(experimentSegmentsMap).length;

    if (experimentsEnabled) {
      const maxPercentile = Object.values(experimentSegmentsMap).reduce(
        (acm, { weight }) => acm + weight,
        0
      );

      const chosenPercentile = Math.random() * maxPercentile;

      const { segment } = Object.entries(experimentSegmentsMap).reduce(
        (acm, [segmentName, { weight }]) => {
          const lowerBound = acm.totalPercentile;
          const upperBound = acm.totalPercentile + weight - 0.000000001;

          if (
            acm.segment === null &&
            chosenPercentile >= lowerBound &&
            chosenPercentile < upperBound
          ) {
            return {
              segment: segmentName,
              totalPercentile: upperBound,
            };
          }

          return { ...acm, totalPercentile: upperBound };
        },
        { segment: null, totalPercentile: 0 } as {
          segment: string | null;
          totalPercentile: number;
        }
      );

      return segment as string;
    }

    return null;
  }, [experimentSegmentsMap]);

  const setExperimentSegmentCookie = useCallback(
    (segmentName: string) => {
      const { expiry } = experimentSegmentsMap[segmentName];

      const expiryDate = prismic.asDate(expiry as TimestampField) as Date;

      setCookie(COOKIE_KEY.EXPERIMENT_SEGMENT, segmentName, {
        expires: expiryDate,
      });

      setExperimentSegment(segmentName);
      setEnabledSegments(currentEnabledSegments => [
        ...new Set([...currentEnabledSegments, segmentName]),
      ]);
    },
    [experimentSegmentsMap, setExperimentSegment]
  );

  const assignNewExperimentSegment = useCallback(() => {
    const newSegment = getWeightedSegment();

    if (newSegment === null) {
      setExperimentSegment('');
    } else {
      setExperimentSegmentCookie(newSegment);
    }
  }, [setExperimentSegmentCookie, getWeightedSegment, setExperimentSegment]);

  useEffect(() => {
    setEnabledSegments(enabledSegments);

    const existingSegment = getCookie(COOKIE_KEY.EXPERIMENT_SEGMENT);

    if (existingSegment) {
      const isValidSegment = Object.keys(experimentSegmentsMap).includes(
        existingSegment
      );

      if (isValidSegment) {
        setExperimentSegmentCookie(existingSegment);
      } else {
        removeCookie(COOKIE_KEY.EXPERIMENT_SEGMENT);
        assignNewExperimentSegment();
      }
    } else {
      assignNewExperimentSegment();
    }
  }, []);

  useInitializeStatsig();
};

export const useActiveExperiment = (experiment: EXPERIMENTS) => {
  const currentEnabledSegments = useRecoilValue(enabledSegments);

  return currentEnabledSegments.includes(experiment);
};

export const useStatsigExperiment = ({
  experimentName,
  groupName,
  inverse,
}: {
  experimentName: STATSIG_EXPERIMENTS;
  groupName?: string;
  inverse?: boolean;
}) => {
  const statsigIsInitialized = useRecoilValue(atoms.statsigIsInitialized);
  const [loading, setLoading] = useState(true);
  const [enabled, setEnabled] = useState(false);

  useEffect(
    function getExperimentGroupEnabledState() {
      if (statsigIsInitialized) {
        if (!groupName) {
          setEnabled(!inverse);
        } else {
          const checkExperiment = (() => {
            switch (experimentName) {
              case STATSIG_EXPERIMENTS.stripeIntegration: {
                const checkStripeExperiment = Statsig.checkGate(
                  STATSIG_FEATURE_GATES.stripeIntegration
                );

                if (!checkStripeExperiment) {
                  const defaultPaymentGatewayIsStripe = Statsig.checkGate(
                    STATSIG_FEATURE_GATES.defaultPaymentGatewayStripe
                  );

                  setEnabled(
                    inverse
                      ? !defaultPaymentGatewayIsStripe
                      : defaultPaymentGatewayIsStripe
                  );
                }

                return checkStripeExperiment;
              }
              default: {
                return true;
              }
            }
          })();

          if (checkExperiment) {
            const experiment = Statsig.getExperiment(experimentName);

            const group = experiment.getGroupName();

            if (group?.includes(groupName)) {
              setEnabled(!inverse);
            } else {
              setEnabled(Boolean(inverse));
            }
          }
        }
      }
      setLoading(!statsigIsInitialized);
    },
    [statsigIsInitialized, experimentName, groupName]
  );

  return [enabled, loading];
};

export const useLogEvent = (
  { sendOnce }: { sendOnce: boolean } = { sendOnce: true }
) => {
  const statsigIsInitialized = useRecoilValue(atoms.statsigIsInitialized);

  const [eventArgsQueue, setEventArgsQueue] = useState<
    Record<string, Parameters<typeof Statsig.logEvent>>
  >({});

  const firedEventKeysRef = useRef<string[]>([]);

  useEffect(
    function logStatsigEventOnceInitialized() {
      if (statsigIsInitialized && Object.keys(eventArgsQueue).length) {
        Object.entries(eventArgsQueue).forEach(([eventKey, eventArgs]) => {
          if (sendOnce && firedEventKeysRef.current.includes(eventKey)) return;

          Statsig.logEvent(...eventArgs);
        });

        if (sendOnce) {
          Object.keys(eventArgsQueue).forEach(eventKey => {
            firedEventKeysRef.current.push(eventKey);
          });
        }

        setEventArgsQueue({});
      }
    },
    [eventArgsQueue, statsigIsInitialized, sendOnce]
  );

  return useCallback(
    (
      ...args: [
        STATSIG_EVENTS,
        ...OmitFirstTuple<Parameters<typeof Statsig.logEvent>>
      ]
    ) => {
      setEventArgsQueue(existingQueue => ({
        ...existingQueue,
        [JSON.stringify(args)]: args,
      }));
    },
    []
  );
};

const useInitializeStatsig = () => {
  const [isStatsigInitialized, setStatsigIsInitialized] = useRecoilState(
    atoms.statsigIsInitialized
  );

  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      (!Statsig.initializeCalled() || !isStatsigInitialized)
    ) {
      (async () => {
        await Statsig.initialize(
          process.env.GATSBY_STATSIG_SDK_KEY,
          {},
          { environment: { tier: process.env.NODE_ENV } }
        );

        setStatsigIsInitialized(true);
      })();
    }
  }, [isStatsigInitialized]);
};
